import '../scss/main.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Fantastic from '../scss/img/Fantastic.png';
import FooterLogo from '../scss/img/footer-logo.png';
import Logo from '../scss/img/logo.svg';
import TagLine from '../scss/img/tag-line.svg';

const Predicted: React.FC = () => {

    const navigate = useNavigate();
    const name = localStorage.getItem('name');
    const round = localStorage.getItem('round');

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const home = () => {
        if (round === 'Round Robbin') {
            navigate('/user/round-robbin'); // Handle Navigation acoording to rounds later
        } else {
            navigate('/user/knockouts'); // Handle Navigation acoording to rounds later
        }
    };
    return (
        <>
            <header data-bs-theme="dark">
                <div className="navbar shadow-sm">
                    <div className="container">
                        <a href='/#' className="navbar-brand d-flex align-items-center">
                            <img src={Logo} alt="Logo" />
                        </a>
                        <div className="ms-auto text-white text-end me-4">Welcome, {name}! <br /></div>
                            <button
                                className="navbar-toggler collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarHeader"
                                aria-controls="navbarHeader"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                            <span className="icon dots"></span>
                            <span className="icon icon-close"></span>
                        </button>
                    </div>
                </div>
            </header>
            <div className="collapse" id="navbarHeader">
                <ul>
                    <li><a className="" href="/user/round-robbin">Match Prediction</a></li>
                    <li><a className="" href="/#">Matches Schedule</a></li>
                    <li><a href='/user/leaderboard'>Leaderboard</a></li>
                    <li><a href="/#" onClick={handleLogout}>Logout <span className="icon icon-logout"></span></a></li>
                </ul>
            </div>
            <div className="banner-image text-center">
            <img alt='' height="24" className="mb-4" src={Logo} />
            <img alt='' height="57" src={TagLine} />
        </div>
            <section className="login-section  p-3">
                <div className="login-inner">
                    <div className="login-form">
                        <form>
                            <div className="header text-center text-blue mt-2 mb-3">
                                <h2 className="title mb-0">Fantastic!<img alt='' src={Fantastic} /></h2>
                                <h5 className="sub-title">Your Prediction is Successful.</h5>
                            </div>
                            <div className="mb-4">
                                <div className="warning">
                                    We will Email you if you Win!
                                </div>
                            </div>
                            <div className="d-grid gap-4">
                                <button className="btn btn-choco btn-lg" onClick={home}><span className="icon icon-left"></span> Home</button>
                                <button className="btn btn-white btn-lg" onClick={handleLogout}>Logout <span className="icon icon-logout"></span></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="brand-footer">
                    <img alt='' className="img-fluid  mx-auto d-block" src={FooterLogo} />
                </div>
            </section>
        </>
    );
};

export default Predicted;
