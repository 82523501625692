import React, { useEffect, useRef, useState } from 'react';
import InternalHeader from './InternalHeader';
import { api } from '../../services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../scss/main.css';
import { useNavigate } from 'react-router-dom';

interface Match {
    match_id: number;
    round: string;
    winner_id: number | null;
    team1_id: number;
    team1_name: string;
    team1_flag: string;
    team2_id: number;
    team2_name: string;
    team2_flag: string;
}

const Knockouts: React.FC = () => {

    const nextRoundMap: { [key: string]: number } = {
        'Round16': 8,
        'Quarterfinals': 4,
        'Semifinals': 2,
        'Final': 1
    };
    
    const [matches, setMatches] = useState<Match[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<{ [key: number]: number | null }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [hasPredicted, setHasPredicted] = useState<boolean>(false);
    const navigate = useNavigate();
    const modalRef = useRef<HTMLDivElement>(null); // Create a ref for the modal
    const round = (localStorage.getItem('round') as string) || 'Round16';

    useEffect(() => {
        navigate('/user/goals');
        const fetchMatchesAndPredictionStatus = async () => {
            try {
                const token = localStorage.getItem('token');
                
                // Fetch matches
                const matchesResponse = await api.get(`/matches/round/${round}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMatches(matchesResponse.data);
                
                // Check if the user has already made predictions
                const predictionStatusResponse = await api.get(`/predictions/knockout/check/${round}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setHasPredicted(predictionStatusResponse.data.hasPredicted);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatchesAndPredictionStatus();
    }, [round]);

    const handleCheckboxChange = (matchId: number, teamId: number) => {
        setSelectedTeams(prevSelectedTeams => ({
            ...prevSelectedTeams,
            [matchId]: prevSelectedTeams[matchId] === teamId ? null : teamId
        }));
    };

    const handleGoals = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/user/goals');
      };
    

    const placeBet = async () => {
        // Check if a team has been selected from each match
        const allMatchesSelected = matches.every(match => selectedTeams[match.match_id]);
        if (!allMatchesSelected) {
            toast.error('Please select a team from each match.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const predictions = Object.entries(selectedTeams).map(([matchId, teamId]) => ({ matchId: parseInt(matchId), teamId: teamId! }));
            await api.post('/predictions/knockout', { matchResults: predictions, round: round }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            navigate('/user/goals');

            // Remove the backdrop
            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                modalBackdrop.remove();
            }
        } catch (error: any) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <>
            <ToastContainer />
            <InternalHeader></InternalHeader>
            <section className="card-section p-3">
                <div className="section-header text-white text-center">
                    <h1 className="title">Predict Now!</h1>
                    <div className="d-grid">
                        <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            disabled={hasPredicted}
                        >
                            Predict {nextRoundMap[round]}
                        </button>
                        {hasPredicted && (
                            <div className="alert alert-warning mt-2">
                                You have already made predictions for this round.
                            </div>
                        )}
                    </div>
                </div>
                <div className="cards">
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        matches.map(match => (
                            <div className="single" key={match.match_id}>
                                <div className="team-info">
                                    <div className="card-bg">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedTeams[match.match_id] === match.team1_id}
                                                onChange={() => handleCheckboxChange(match.match_id, match.team1_id)}
                                                id={`check${match.team1_id}`}
                                                disabled={hasPredicted}
                                            />
                                            <label className="form-check-label">
                                                <img src={match.team1_flag} alt={match.team1_name} />{match.team1_name}
                                            </label>
                                        </div>
                                    </div>
                                    <span>VS</span>
                                    <div className="card-bg">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedTeams[match.match_id] === match.team2_id}
                                                onChange={() => handleCheckboxChange(match.match_id, match.team2_id)}
                                                id={`check${match.team2_id}`}
                                                disabled={hasPredicted}
                                            />
                                            <label className="form-check-label">
                                                <img src={match.team2_flag} alt={match.team2_name} />{match.team2_name}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </section>
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content card card-bg">
                        <div className="modal-body card-inner">
                            <div className="header text-center text-blue mt-2 mb-4">
                                <span className="top-title">Your Final</span>
                                <h2 className="title mb-0 mt-1">Last {nextRoundMap[round]} selection</h2>
                            </div>
                            <div className="group-team">
                                {Object.entries(selectedTeams).map(([matchId, teamId]) => {
                                    const match = matches.find(match => match.match_id === parseInt(matchId));
                                    if (!match || !teamId) return null;
                                    const team = teamId === match.team1_id ? { name: match.team1_name, flag: match.team1_flag } : { name: match.team2_name, flag: match.team2_flag };
                                    return (
                                        <div className="team-info" key={matchId}>
                                            <img src={team.flag} alt={team.name} /><span>{team.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="login d-flex pe-2 ps-2">
                                <button type="button" data-bs-dismiss="modal" className="btn btn-danger btn-md">
                                    <span className="icon edit"></span>Change
                                </button>
                                <button type="button" onClick={placeBet} className="btn btn-primary btn-lg">
                                    I’m Certain!
                                </button>
                            </div>
                            <a type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span className="icon icon-close"></span>
                            </a>
                            <button type="button" className="btn-close btn-close-white" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Knockouts;
