import './components/scss/main.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ForgotPassword from './components/Forgot';
import Home from './components/Home';
import Signup from './components/Signup';
import AdminDashboard from './pages/AdminDashboard';
import LoginPage from './pages/LoginPage';
import UserDashboard from './pages/UsersDashboard';
import { setAuthToken } from './services/api';


const App: React.FC = () => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/home" element={<Home />} />
      <Route path="/admin/*" element={<AdminDashboard />} />
      <Route path="/user/*" element={<UserDashboard />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default App;
