import React, { useState } from 'react';
import { api } from '../services/api';
import './scss/main.css';
import Footer from './Footer';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validatePassword = (password: string) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        return re.test(password);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!isCodeSent) {
            if (!validateEmail(email)) {
                toast.error('Invalid email format. Please include "@" and "." in the email.');
                return;
            }

            try {
                await api.post('/users/forgot-password', { email });
                toast.success('Password reset code sent! Please check your inbox.');
                setIsCodeSent(true);
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        } else {
            // if (!validatePassword(password)) {
            //     toast.error('Password must be at least 6 characters long and include one uppercase letter, one lowercase letter, one number, and one special character.');
            //     return;
            // }

            if (password !== confirmPassword) {
                toast.error('Passwords do not match.');
                return;
            }

            try {
                await api.post('/users/reset-password', { email, code, password });
                toast.success('Password updated successfully! You can now login.');
                navigate('/login');
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        }
    };

    return (
        <div className='main'>
            <ToastContainer />
            <Header></Header>
            <section className="login-section p-3">
                <div className="login-inner forgot-password">
                    <div className="login-form">
                        <form onSubmit={handleSubmit}>
                            {!isCodeSent ? (
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Please enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                            ) : (
                                <>
                                    <div className="mb-3">
                                        <label className="form-label">Code</label>
                                        <input type="text" className="form-control" placeholder="Enter the code sent to your email" value={code} onChange={(e) => setCode(e.target.value)} required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">New Password</label>
                                        <input type="password" className="form-control" placeholder="6+ strong characters" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Confirm New Password</label>
                                        <input type="password" className="form-control" placeholder="6+ strong characters" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                    </div>
                                </>
                            )}
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary btn-lg">{isCodeSent ? 'Reset Password' : 'Send Email'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    );
};

export default ForgotPassword;
