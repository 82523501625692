import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Define the component type
type ComponentType = React.ComponentType<any>;

// Function to check if the user is authenticated
const checkAuthentication = () => {
    // Implement your authentication logic here
    // For example, check if the user is logged in or if a valid token exists
    const isAuthenticated = localStorage.getItem('token') !== null; // Example: Check if token exists in local storage
    return isAuthenticated;
};

// Function to check if the user is authorized based on their role
const checkAuthorization = (requiredRole: string) => {
    // Implement your authorization logic here
    // For example, check if the user role matches the required role
    const userRole = localStorage.getItem('role'); // Example: Get user role from local storage
    return userRole === requiredRole;
};

// Higher-order component (HOC) for authentication
const withAuthentication = (Component: ComponentType) => {
    const WrapperComponent = (props: any) => {
        const navigate = useNavigate();

        useEffect(() => {
            // Check if the user is authenticated
            if (!checkAuthentication()) {
                // Redirect to login page if not authenticated
                navigate('/login');
            }
        });

        return <Component {...props} />;
    };

    return WrapperComponent;
};

// Higher-order component (HOC) for authorization
const withAuthorization = (Component: ComponentType, requiredRole: string) => {
    const WrapperComponent = (props: any) => {
        const navigate = useNavigate();

        useEffect(() => {
            // Check if the user is authenticated
            if (!checkAuthentication()) {
                // Redirect to login page if not authenticated
                navigate('/login');
            }
            // Check if the user is authorized based on required role
            if (!checkAuthorization(requiredRole)) {
                // Redirect to access denied page or show message if not authorized
                navigate('/access-denied');
            }
        });

        return <Component {...props} />;
    };

    return WrapperComponent;
};

export { withAuthentication, withAuthorization };
