import React, { useEffect, useState } from 'react';
import { api } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';

interface Team {
  team_id: number;
  team_name: string;
  flag_path: string;
}

const AddMatch: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [team1Id, setTeam1Id] = useState<number | null>(null);
  const [team2Id, setTeam2Id] = useState<number | null>(null);
  const round = (localStorage.getItem('round') as string) || 'Round16';
  useEffect(() => {
    const checkTeamsUpdated = async () => {
      try {
        const teamsResponse = await api.get('matches/qualified-teams');
        console.log('Teams response:', teamsResponse.data);  // Log the teams response
        setTeams(teamsResponse.data);
      } catch (error) {
        console.error('Error checking if teams are updated:', error);
      }
    };

    checkTeamsUpdated();
  }, []);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (team1Id === null || team2Id === null) {
      toast.error('Please select both teams.');
      return;
    }
  
    if (team1Id === team2Id) {
      toast.error('Both Teams cannot be the same.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      await api.post(
        'matches/add-match',
        { team1_id: team1Id, team2_id: team2Id, round: round },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast.success('Match added successfully');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  

  return (
    <>
    <ToastContainer />
    <div className="container">
      <div className="container-inner">
      <h2>Add Matches</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className='form-label'>Team 1</label>
          <select className='form-control' value={team1Id ?? ''} onChange={(e) => setTeam1Id(parseInt(e.target.value))}>
            <option value="" disabled>Select Team 1</option>
            {teams.map(team => (
              <option key={team.team_id} value={team.team_id}>{team.team_name}</option>
            ))}
          </select>
        </div>
        <div>
          <label className='form-label'>Team 2</label>
          <select className='form-control' value={team2Id ?? ''} onChange={(e) => setTeam2Id(parseInt(e.target.value))}>
            <option value="" disabled>Select Team 2</option>
            {teams.map(team => (
              <option key={team.team_id} value={team.team_id}>{team.team_name}</option>
            ))}
          </select>
        </div>
        <button type="submit" className='btn btn-success'>Add Match</button>
      </form>
      </div>
    </div>
    </>
  );
};

export default AddMatch;
