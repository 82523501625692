import React, { useState } from 'react';
import { api } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';

const KnockoutResults: React.FC = () => {
  const [matchResults, setMatchResults] = useState<string>('');
  const [round, setRound] = useState<string>('Round16');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const matchResultArray = matchResults.split('\n').map(result => {
      const [matchId, teamId] = result.split(',').map(id => parseInt(id.trim()));
      return { matchId, teamId };
    });
    try {
      await api.post('result/knockout', { matchResults: matchResultArray, round });
      toast.success(`${round} results updated successfully`);
    } catch (error: any) {
      console.error(`Error updating ${round} results:`, error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="container-inner">
          <h2>Update Knockout Results</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label className='form-label'>Round</label>
              <select className='form-control' value={round} onChange={(e) => setRound(e.target.value)}>
                <option value="Round16">Round of 16</option>
                <option value="Quarterfinals">Quarterfinals</option>
                <option value="Semifinals">Semifinals</option>
                <option value="Final">Final</option>
              </select>
            </div>
            <div>
              <label className='form-label'>Match Results (matchId, teamId per line)</label>
              <textarea
                className='form-container'
                value={matchResults}
                onChange={(e) => setMatchResults(e.target.value)}
                placeholder="e.g. 1, 2\n3, 4\n..."
                rows={8}
                cols={40}
              />
            </div>
            <button type="submit" className='btn btn-success'>Update Results</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default KnockoutResults;
