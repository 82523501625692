import React, { useEffect, useState } from 'react';
import '../scss/main.css';
import InternalHeader from './InternalHeader';
import { api } from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Team {
    team_id: string;
    team_name: string;
    flag_path: string;
}

interface Group {
    group_name: string;
    teams: Team[];
}

const RoundRobbin: React.FC = () => {
    const [groups, setGroups] = useState<Group[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [hasPredicted, setHasPredicted] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMatchesAndPredictionStatus = async () => {
            try {
                const token = localStorage.getItem('token');
                
                // Fetch matches
                const matchesResponse = await api.get('matches/groupandteam', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setGroups(matchesResponse.data);
                
                // Check if the user has already made predictions
                const predictionStatusResponse = await api.get('predictions/round-robin/check', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setHasPredicted(predictionStatusResponse.data.hasPredicted);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatchesAndPredictionStatus();
    }, []);

    const handleCheckboxChange = (teamId: string) => {
        setSelectedTeams(prevSelectedTeams => {
            let updatedSelection;
            if (prevSelectedTeams.includes(teamId)) {
                updatedSelection = prevSelectedTeams.filter(id => id !== teamId);
            } else if (prevSelectedTeams.length < 16) {
                updatedSelection = [...prevSelectedTeams, teamId];
            } else {
                updatedSelection = prevSelectedTeams; // No change if already 16 selected
            }

            return updatedSelection;
        });
    };

    const validateSelections = () => {
        if (selectedTeams.length !== 16) {
            toast.error("Please select exactly 16 teams in total");
            return false;
        } else {
            setValidationErrors([]);
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!validateSelections()) {
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await api.post('/predictions/round-robin', {
                teamIds: selectedTeams,
                round: 1,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Successfully submitted:', response.data); // Navigate later
            navigate('/user/placed');
        } catch (error: any) {
            toast.error(error.response.data.message);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
        <ToastContainer />
            <InternalHeader />
            <section className="card-section p-3">
                <div className="section-header text-white text-center">
                    <div className="match-number">Who do you think will be in the Last-16</div>
                    <h1 className="title">Predict Now!</h1>
                    <div className="d-grid">
                        <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={handleSubmit}
                            disabled={hasPredicted}
                        >
                            Predict 16 ({selectedTeams.length}/16)
                        </button>
                        {hasPredicted && (
                            <div className="alert alert-warning mt-2">
                                You have already made predictions for this round.
                            </div>
                        )}
                    </div>
                </div>
                {validationErrors.length > 0 && (
                    <div className="alert alert-danger">
                        {validationErrors.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </div>
                )}
                {groups.map((group, groupIndex) => (
                    <div className="cards" key={groupIndex}>
                        <div className="card card-bg mb-2">
                            <div className="card-inner">
                                <div className="group p-2 pb-3">
                                    <label className="text-blue">{group.group_name}</label>
                                    <div className="group-inner">
                                        <div className="group-info">
                                            {group.teams.map((team) => (
                                                <div className="form-check" key={team.team_id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={team.team_id}
                                                        id={`check${team.team_id}`}
                                                        checked={selectedTeams.includes(team.team_id)}
                                                        onChange={() => handleCheckboxChange(team.team_id)}
                                                        disabled={hasPredicted}
                                                    />
                                                    <label className="form-check-label" htmlFor={`check${team.team_id}`}>
                                                        <img alt={team.team_name} src={team.flag_path} /> {team.team_name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </>
    );
};

export default RoundRobbin;
