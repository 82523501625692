import React from 'react';
import styles from './scss/Footer.module.scss';
import FooterLogo from './scss/img/footer-logo.png';
import Ball from './scss/img/boll.svg';
import BallNew from './scss/img/boll-2.svg';

const Footer: React.FC = () => {
    return (
        <div >
            <div className={styles['brand-footer']}>
                <img className="img-fluid mx-auto d-block" src={FooterLogo} alt="Footer Logo" />
            </div>
            <div className="bg-elements">
                <img alt='' className="right-side" src={Ball} />
                <img alt='' className="left-bottom" src={BallNew} />
            </div>
        </div>
    );
};

export default Footer;
