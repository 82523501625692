import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './scss/Navbar.scss';

const Navbar: React.FC = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    return (
        <nav>
            <ul>
                <li><Link to="/admin/add-match">Add Match</Link></li>
                <li><Link to="/admin/update-roundrobbin">Update Round Robbins</Link></li>
                <li><Link to="/admin/update-knockouts">Update Knockouts</Link></li>
                <li><Link to="/admin/leaderboard">Leaderboard</Link></li>
                <li><button onClick={handleLogout}>Logout</button></li>
            </ul>
        </nav>
    );
};

export default Navbar;
