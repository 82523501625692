import React from 'react';
import Header from './Header';
import Footer from './Footer';



const Home: React.FC = () => {
  return (
    <div className='main'>
        <Header></Header>
      <section className="login-section  p-3">
            <div className="login-inner">
                <div className="brand-image">
                    <img alt='' className="img-fluid  mx-auto d-block" src="./scss/img/Snickers.png" />
                </div>
                <div className="login d-grid gap-4">
                    <a type="button" href="/login" className="btn btn-primary btn-lg btn-block">Sign In</a>
                    <a type="button" href="/signup" className="btn btn-danger  btn-lg btn-block">Sign Up</a>
                </div>
            </div>
            <div className="brand-footer">
                <img alt='' className="img-fluid mx-auto d-block" src="./scss/img/footer-logo.png" />
            </div>
        </section>
        <Footer></Footer>
    </div>
  );
};

export default Home;
