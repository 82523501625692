import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, setAuthToken } from '../services/api';
import './scss/main.css';
import Header from './Header';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';



const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await api.post('/users/login', { username, password });
            const { token, role, round, name, qualify } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('round', round);
            setAuthToken(token);
            if (role === 'admin') {
                localStorage.setItem('role', role);
                if (round === 'Round Robbin') {
                    navigate('/admin/update-roundrobbin');
                } else {
                    navigate('/admin/update-knockouts'); // Handle Navigation acoording to rounds later
                }
            } else {
                if (qualify !== 0) {
                    localStorage.setItem('name', name);
                    if (round === 'Round Robbin') {
                        navigate('/user/round-robbin'); // Handle Navigation acoording to rounds later
                    } else {
                        navigate('/user/goals'); // Handle Navigation acoording to rounds later
                    }
                } else {
                    toast.error('Sorry, You have not qualified for next round! Better Luck next year!')
                }
            }
        } catch (error) {
            toast.error('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className='main'>
            <ToastContainer />
            <Header></Header>
            <section className="login-section p-3">
                <div className="login-inner">
                    {error && <p className='error-message'>{error}</p>}
                    <div className="login-form">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required className="form-control"
                                    aria-describedby="emailHelp" placeholder="Type your email" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required className="form-control"
                                    placeholder="Type your password" />
                            </div>
                            <div className="mb-5 form-check ">
                                <a className="form-label" href="/forgot">Forgot Password?</a>
                            </div>

                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary btn-lg ">Sign In</button>
                            </div>
                        </form>
                    </div>
                </div>

            </section>
            <Footer></Footer>
        </div>
    );
};

export default Login;
