import React from 'react';
import styles from './scss/Header.module.scss';
import Logo from './scss/img/logo.png';
import TagLine from './scss/img/tag-line.svg';

const Header: React.FC = () => {
    return (
        <div className={`${styles['banner-image']} text-center`}>
            <img height="24" className="mb-4" src={Logo} alt="Logo" />
            <img height="57" src={TagLine} alt="Tagline" />
        </div>
    );
};

export default Header;
