import Logo from '../scss/img/logo.png';
import '../scss/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useNavigate } from 'react-router-dom';

const InternalHeader: React.FC = () => {
    const navigate = useNavigate();
    const name = localStorage.getItem('name');

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };
    return (
        <>
            <div className="header-container">
                <header data-bs-theme="dark">
                    <div className="navbar shadow-sm">
                        <div className="container">
                            <a className="navbar-brand d-flex align-items-center" href="/#">
                                <img src={Logo} alt="Logo" />
                            </a>
                            <div className="ms-auto text-white text-end me-4">
                                Welcome, {name}! <br />
                            </div>
                            <button
                                className="navbar-toggler collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarHeader"
                                aria-controls="navbarHeader"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon dots"></span>
                                <span className="icon icon-close"></span>
                            </button>
                        </div>
                    </div>
                </header>
                <div className="collapse" id="navbarHeader">
                    <ul>
                        <li><a href="/#" onClick={handleLogout}>Logout <span className="icon icon-logout"></span></a></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default InternalHeader;
