import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import AddMatch from '../components/AddMatch';
import styles from './AdminDashboard.module.scss'; // Import SCSS styles
import { withAuthorization } from '../utils/auth';
import RoundRobinResults from '../components/RoundRobinResults';
import KnockoutResults from '../components/KnockoutResults';
import Leaderboard from '../components/Leaderboard';

const ProtectedAddMatch = withAuthorization(AddMatch, 'admin');
const ProtectedRR = withAuthorization(RoundRobinResults, 'admin');
const ProtectedKC = withAuthorization(KnockoutResults, 'admin');
const ProtectedLeaderboard = withAuthorization(Leaderboard, 'admin');

const AdminDashboard: React.FC = () => {
    return (
        <div className={styles['admin-dashboard']}>
            <Navbar />
            <div className={styles['dashboard-content']}>
                <div className={styles['content']}>
                    <Routes>
                        <Route path="/" element={<h2 className={styles['dashboard-header']}>Welcome to Admin Dashboard</h2>} />
                        <Route path="/add-match" element={<ProtectedAddMatch />} />
                        <Route path="/update-roundrobbin" element={<ProtectedRR />} />
                        <Route path="/update-knockouts" element={<ProtectedKC />} />
                        <Route path="/leaderboard" element={<ProtectedLeaderboard />} />
                    </Routes>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
