import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api } from '../services/api';

interface User {
    id: string;
    username: string;
    email: string;
    points: number;
    qualify: number;
}

const Leaderboard: React.FC = () => {
    const [percent, setPercent] = useState<number>(0);
    const [qualifiedUsers, setQualifiedUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchQualifiedUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/admin/leaderboard`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setQualifiedUsers(response.data);
        } catch (error) {
            console.error('Error fetching qualified users:', error);
            toast.error('Failed to fetch qualified users');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQualifiedUsers();
    }, []);

    const handleQualifyUsers = async (e: React.FormEvent) => {
        e.preventDefault();
        if (percent <= 0 || percent > 100) {
            toast.error('Please enter a valid percentage between 1 and 100');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            await api.post(
                'admin/qualify',
                { percent },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            toast.success(`Top ${percent}% of users have been qualified for the next round`);
            fetchQualifiedUsers(); // Refresh the list after qualifying users
        } catch (error: any) {
            toast.error(error.response.data.message);
        }
    };

    const handleNotify = async () => {
        try {
            await api.post('/admin/notify-qualified-users');
            toast.success('Emails sent to all qualified users');
        } catch (error) {
            console.error('Error sending notifications:', error);
            toast.error('Failed to send notifications');
        }
    };

    const handleDeleteUser = async (userId: string) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/admin/user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            toast.success('User deleted successfully');
            setQualifiedUsers(qualifiedUsers.filter(user => user.id !== userId));
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('Failed to delete user');
        }
    };

    const convertToCSV = (data: User[]) => {
        const csvRows = [];
        const headers = ['Username', 'Email', 'Points', 'isQualified'];
        csvRows.push(headers.join(','));

        data.forEach(user => {
            const values = [
                user.username,
                user.email,
                user.points.toString(),
                user.qualify === 1 ? 'Yes' : 'No'
            ];
            csvRows.push(values.join(','));
        });

        return csvRows.join('\n');
    };

    const downloadCSV = () => {
        const csvData = convertToCSV(qualifiedUsers);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'leaderboard.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div className="container">
            <ToastContainer />
            <div className="container-inner">

                <h2>Qualify Users</h2>
                <form onSubmit={handleQualifyUsers}>
                    <div className="form-group">
                        <label htmlFor="percent" className='form-label'>Percentage of Users to Qualify:</label>
                        <input
                            type="number"
                            id="percent"
                            className="form-control"
                            value={percent}
                            onChange={(e) => setPercent(Number(e.target.value))}
                            min="1"
                            max="100"
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-success">Qualify Users</button>
                </form>

                <button onClick={handleNotify} className="btn btn-success">
                    Notify Qualified Users
                </button>
            </div>

            <h2 className="mt-4">Leaderboard</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <button onClick={downloadCSV} className="btn btn-primary mb-3">
                        Download CSV
                    </button>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Points</th>
                                <th>isQualified</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {qualifiedUsers.map((user, index) => (
                                <tr key={index}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.points}</td>
                                    <td>{user.qualify === 1 ? 'Yes' : 'No'}</td>
                                    <td>
                                        <button
                                            onClick={() => handleDeleteUser(user.id)}
                                            className="btn btn-danger"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default Leaderboard;
