import React, { useState } from 'react';
import { api } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';

const RoundRobinResults: React.FC = () => {
  const [teamIds, setTeamIds] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const teamIdArray = teamIds.split(',').map(id => parseInt(id.trim()));
    const token = localStorage.getItem('token');
    try {
      await api.post(
        'result/round-robin',
        { teamIds: teamIdArray },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      toast.success('Round Robin results updated successfully');
    } catch (error: any) {
      console.error('Error Updateing round:', error);
      toast.error(error.response.data.message);
    }
  };


  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="container-inner">
          <h2>Update Round Robin Results</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label className='form-label'>Team IDs (comma separated)</label>
              <input className='form-control'
                type="text"
                value={teamIds}
                onChange={(e) => setTeamIds(e.target.value)}
                placeholder="e.g. 1, 2, 3, 4"
              />
            </div>
            <button type="submit" className='btn btn-success'>Update Results</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default RoundRobinResults;
