import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import BallNew from '../components/scss/img/boll-2.svg';
import Ball from '../components/scss/img/boll.svg';
import Knockouts from '../components/users/Knockouts';
import Predicted from '../components/users/Fantastic';
import RoundRobbin from '../components/users/RoundRobbin';
import { withAuthentication } from '../utils/auth';
import Goals from '../components/users/Goals';

const ProtectedRoundRobbin = withAuthentication(RoundRobbin);
const ProtectedKnockouts = withAuthentication(Knockouts);
const ProtectedGoals = withAuthentication(Goals);
const ProtectedPlaced = withAuthentication(Predicted);

const UserDashboard: React.FC = () => {
    return (
        <div className="main">
            <Routes>
                <Route path="/round-robbin" element={<ProtectedRoundRobbin />} />
                <Route path="/knockouts" element={<ProtectedKnockouts />} />
                <Route path="/goals" element={<ProtectedGoals />} />
                <Route path="/placed" element={<ProtectedPlaced />} />
            </Routes>
            <Outlet />
            <div className="bg-elements">
                <img className="right-side" src={Ball} alt="Ball" />
                <img className="left-bottom" src={BallNew} alt="Ball New" />
            </div>
        </div>
    );
};

export default UserDashboard;
