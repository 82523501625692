import React, { useState } from 'react';
import { api } from '../services/api';
import './scss/main.css';
import Footer from './Footer';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Signup: React.FC = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validatePassword = (password: string) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        return re.test(password);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            toast.error('Invalid email format. Please include "@" and "." in the email.');
            return;
        }

        // if (!validatePassword(password)) {
        //     toast.error('Password must be at least 6 characters long and include one uppercase letter, one lowercase letter, one number, and one special character.');
        //     return;
        // }

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }

        try {
            await api.post('/users/signup', { username, email, password });
            navigate('/login');
            toast.success('Signup successful! You can now login.');
        } catch (error: any) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <div className='main'>
            <ToastContainer />
            <Header></Header>
            <section className="login-section  p-3">
                <div className="login-inner sign-up">
                    <div className="login-form ">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required aria-describedby="emailHelp" placeholder="Type your name" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Please enter your Company email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input type="password" className="form-control" placeholder="6+ strong characters" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Confirm Password</label>
                                <input type="password" className="form-control" placeholder="6+ strong characters" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                            </div>

                            <div className="d-grid">
                                <button type="submit" className="btn btn-danger btn-lg ">Sign Up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    );
};

export default Signup;
